import React from "react";
import "../styles/CV.css";
import ParticleBackground from "../components/ParticleBackground";

const CV = () => {
  const skills = {
    frontend: [
      "TypeScript",
      "JavaScript",
      "Swift",
      "React",
      "React Native",
      "iOS",
      "Android",
      "HTML5",
      "CSS3",
    ],
    backend: [
      "TypeScript",
      "JavaScript",
      "Node.js",
      "Express",
      "REST API",
      "NoSQL",
      "MongoDB",
      "DynamoDB",
      "Serverless",
    ],
    cloud: ["AWS"],
    tools: ["Git", "XCode", "Android Studio", "VS Code", "Agile / Scrum"],
  };

  return (
    <div className="cv-container">
      <ParticleBackground />
      <div className="cv-content">
        <h1 className="cv-title">Ansioluettelo</h1>

        <section className="cv-section">
          <h2>Työkokemus</h2>
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-date">Tammi 2025 - Nykyhetki</div>
              <div className="timeline-content">
                <h3>Sivutoiminen yrittäjä</h3>
                <p className="company">Webean Oy</p>
                <p>Verkkosivustojen kehittäminen ja ylläpito.</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-date">2022 - Nykyhetki</div>
              <div className="timeline-content">
                <h3>Ohjelmistokehittäjä</h3>
                <p className="company">Twoday Oy</p>
                <p>
                  Vastuualueina uusien ominaisuuksien suunnittelu ja toteutus,
                  bugien korjaus sekä koodin ylläpito. Työskentely modernilla
                  teknologiastackilla, painottaen laadukasta ja skaalautuvaa
                  koodia, joka vastaa käyttäjien tarpeita.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-date">Kesä 2021 - Elo 2021</div>
              <div className="timeline-content">
                <h3>Torninosturinkuljettaja</h3>
                <p className="company">Crane Crew Oy</p>
                <p>
                  Opiskelun ohella tein tuttuja työtehtäviä kesäloman aikana.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-date">Touko 2020 - Elo 2020</div>
              <div className="timeline-content">
                <h3>Torninosturinkuljettaja</h3>
                <p className="company">Uniflex Suomi Oy</p>
                <p>
                  Opiskelun ohella tein tuttuja työtehtäviä kesäloman aikana.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-date">2017 - 2019</div>
              <div className="timeline-content">
                <h3>Työnjohtaja ja myyntiedustaja</h3>
                <p className="company">Misorak Oy</p>
                <p>
                  Tehtäväni oli laajentaa yrityksen operatiivista toimintaa
                  Länsi-Suomen alueelle vastata sen toiminnasta. Lisäksi
                  työtehtäviin kuuluivat myynti, työnjohto, HR sekä
                  tuotekehitys.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-date">2012 - 2019</div>
              <div className="timeline-content">
                <h3>Torninosturinkuljettaja</h3>
                <p className="company">Misorak Oy</p>
                <p>
                  Toimin torninosturinkuljettajana useissa projekteissa mm. Uusi
                  lastensairaala ja Aalto yliopisto.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-date">2010 - 2012</div>
              <div className="timeline-content">
                <h3>Rakennusmies / Elementtiasennuksen tekijä</h3>
                <p className="company">YIT Suomi Oy</p>
                <p>
                  Työtehtäviin kuuluivat rakennusmiehen työt sekä
                  elementtiasennus.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="cv-section">
          <h2>Koulutus</h2>
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-date">Syksy 2019 - Syksy 2022</div>
              <div className="timeline-content">
                <h3>IT-tradenomi (AMK)</h3>
                <p className="company">
                  Turun Ammattikorkeakoulu - Tietojenkäsittely
                </p>
              </div>
            </div>
          </div>
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-date">2012 - 2012</div>
              <div className="timeline-content">
                <h3>Torninosturinkuljettajan ammattitutkinto</h3>
                <p className="company">Faktia Oy</p>
              </div>
            </div>
          </div>
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-date">2007 - 2010</div>
              <div className="timeline-content">
                <h3>Talonrakennusalan perustutkinto</h3>
                <p className="company">Raision ammattikoulu</p>
              </div>
            </div>
          </div>
        </section>

        <section className="cv-section">
          <h2>Teknologiaosaaminen</h2>
          <div className="skills-container">
            <div className="skills-category">
              <h3>Frontend</h3>
              <div className="skills-grid">
                {skills.frontend.map((skill) => (
                  <div key={skill} className="skill-item">
                    {skill}
                  </div>
                ))}
              </div>
            </div>
            <div className="skills-category">
              <h3>Backend</h3>
              <div className="skills-grid">
                {skills.backend.map((skill) => (
                  <div key={skill} className="skill-item">
                    {skill}
                  </div>
                ))}
              </div>
            </div>
            <div className="skills-category">
              <h3>Pilvipalvelut</h3>
              <div className="skills-grid">
                {skills.cloud.map((skill) => (
                  <div key={skill} className="skill-item">
                    {skill}
                  </div>
                ))}
              </div>
            </div>
            <div className="skills-category">
              <h3>Työkalut & Menetelmät</h3>
              <div className="skills-grid">
                {skills.tools.map((skill) => (
                  <div key={skill} className="skill-item">
                    {skill}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CV;
